import React, { useEffect } from 'react';

// components
import { NavBar } from '../components/NavBar';
import { Documents } from '../components/Documents';
import { ScrollBackToTop } from '../components/ScrollBackToTop';
import { Footer } from '../components/Footer';

// Styles
import './certificates.scss';

export const Certificates = () => {
	useEffect(() => {
		window.scrollTo({ top: 0, behavior: 'smooth' });
	}, []);
	return (
		<>
			<header>
				<NavBar menuName={'certificates'} />
			</header>
			<main>
				<Documents />
				<ScrollBackToTop />
			</main>
			<footer id="footer">
				<Footer />
			</footer>
		</>
	);
};
