import React from 'react';

// Styles
import './bannergrid.scss';

export const BannerGrid = ({ background }) => {
	const goProjects = () => {
		const searchElement = document.getElementById('works');
		if (searchElement) {
			searchElement.scrollIntoView({ behavior: 'smooth' });
		}
	};

	return (
		<div className="banner2-container">
			<div className="banner-text">{/* <span>VMOG</span> */}</div>
			<div className="banner2">
				<div className="banner2-img-black"></div>
				<div className="banner2-img"></div>
				<div className="banner2-img-black"></div>
				<div className="banner2-img"></div>
				<div className="banner2-img"></div>
				<div className="banner2-img-black"></div>
				<div className="banner2-img"></div>
				<div className="banner2-img-black"></div>
				<div className="banner2-img-black"></div>
				<div className="banner2-img"></div>
				<div className="banner2-img"></div>
				<div className="banner2-img-black"></div>
				<div className="banner2-img-black"></div>
				<div className="banner2-img"></div>
				<div className="banner2-img-black"></div>
				<div className="banner2-img"></div>
			</div>
			<div
				className="banner3"
				style={{ backgroundImage: `url(${background})` }}
			></div>
			<div className="banner-text-2">
				<h2>Victor M. Ottati</h2>
				<h1>MERN Stack Website Developer</h1>
				<button onClick={goProjects} className="btn btn__card">
					Go to my projects...
				</button>
			</div>
		</div>
	);
};
