import React from 'react';

// Styles
import './slider.scss';

// Read JSON Data
const slider = require('../data/slider.json');
const dataSlider = slider.slider;

export const Slider = ({ dataName }) => {
	// Filter JSON Data
	const dataFilt = dataSlider.filter((list) => list.type === dataName);
	const mode = dataFilt[0].mode;

	return (
		<section className={`content-slider ${mode}`}>
			<div className={`slider-text ${mode}`}>
				<div className='slider-container'>
					<h4 className='slider'>{dataFilt[0].text1}</h4>
				</div>
				<div className='slider-container'>
					<h4 className='slider'>{dataFilt[0].text2}</h4>
				</div>
				<div className='slider-container'>
					<h4 className='slider'>{dataFilt[0].text3}</h4>
				</div>
			</div>
		</section>
	);
};
