import React from 'react';

// Components
import { Skills } from './Skills';

// Styles
import './about.scss';

export const AboutComponent = () => {
	return (
		<div className="about-container">
			<h2 className="about-title">
				<span>About</span> Me
			</h2>

			<div className="image-gif"></div>

			<h4 className="about-name">
				Hi there
				<span role="img" aria-label="Hello">
					👋
				</span>
				, I'm Victor M. Ottati
			</h4>

			<h5 align="center">
				A passionate MERN Stack Website Developer based in the UK with
				Venezuelan and Italian (EU) roots.
			</h5>

			<p className="about-description">
				I specialize in crafting captivating websites that seamlessly blend
				functionality and aesthetics using the powerful MERN stack (MongoDB,
				Express, React, and Node.js). What sets me apart is my obsession with
				writing clean, DRY (Don't Repeat Yourself) code that prioritizes
				efficiency, maintainability, and a smooth user experience.
			</p>

			<p className="about-description">
				As a digital architect, I meticulously design intuitive user interfaces
				that keep your audience engaged from the first click. Every element,
				animation, and pixel is carefully crafted to create an unforgettable
				journey, ensuring your users effortlessly navigate and discover all your
				website has to offer.
			</p>
			<p className="about-description">
				With a keen eye for detail and a passion for both engineering and
				artistic expression, I bring a unique blend of technical expertise and
				creative flair to every project. Let's join forces and create an
				extraordinary digital experience that truly resonates with your
				audience.
			</p>

			<p className="about-description">
				Thanks for stopping by, and I can't wait to connect with you soon.
			</p>

			<Skills />

			<div className="links-container">
				<a
					href={'https://vmog.net/assets/pdf/VMO_CV_2023.pdf'}
					target="_blank"
					rel="noopener noreferrer"
					className="resume-link"
				>
					RESUME
				</a>
			</div>
		</div>
	);
};
