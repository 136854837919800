import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

// Components
import { NavBar } from '../components/NavBar';
import { BannerGrid } from '../components/BannerGrid';
import { Slider } from '../components/Slider';
import { Share } from '../components/Share';
import { AboutComponent } from '../components/About';
import { PostSection } from '../components/PostSection';
import { Contact } from '../components/Contact';
import { ScrollBackToTop } from '../components/ScrollBackToTop';
import { Footer } from '../components/Footer';

// Banner
import background from '../assets/images/Banner-1200x800-1.jpg';

// Styles
import './home.scss';

// Read JSON Data
const posts = require('../data/posts-home.json');
const dataPosts = posts.posts;

const Home = () => {
	useEffect(() => {
		window.scrollTo({ top: 0, behavior: 'smooth' });
	}, []);
	return (
		<div className=".general__container vmog-home" id="home">
			<header>
				<NavBar menuName={'home'} />
				<BannerGrid background={background} />
			</header>
			<main>
				<hr />
				<Slider dataName={'home'} />
				<Share url={'vmog.net'} />

				<hr />
				<div>
					<AboutComponent />
				</div>
				<hr />
				<div className="work" id="works">
					<PostSection dataPosts={dataPosts} />
					<div className="link-container">
						<Link to="/projects" className="project-link">
							More Projects
						</Link>
					</div>
				</div>
				<hr />
				<div>
					<Contact />
				</div>
				<hr />
				<ScrollBackToTop />
			</main>

			<footer id="footer">
				<Footer />
			</footer>
		</div>
	);
};

export default Home;
