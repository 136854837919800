import React from 'react';

// Styles
import './timeline.scss';

// Read JSON Data
const dataJason = 'timeline';
const info = require(`../data/${dataJason}.json`);
const dataInfo = info.timeline;

export const Timeline = ({ dataName }) => {
	// Filter JSON Data
	const dataFilt = dataInfo.filter((list) => list.type === dataName);
	const title = dataFilt[0].title;
	const data = dataFilt[0].data;

	return (
		<section className='container-timeline'>
			<div className='container-main'>
				<h2>{title}</h2>

				<div className='timeline'>
					<ul>
						{data.map((item, index) => (
							<li key={item.id}>
								<div
									className='time'
									data-aos='fade-up'
									data-aos-duration='1000'
								>
									<h4>{data[index].time}</h4>
								</div>
								<div
									className='item'
									data-aos='fade-up'
									data-aos-duration='1000'
								>
									<h3>{data[index].title}</h3>
									<p className='hardware'>{data[index].hardware}</p>
									<p className='software'>
										Software: <b>{data[index].software}</b>
									</p>
								</div>
							</li>
						))}
						<div style={{ clear: 'both' }}></div>
					</ul>
				</div>
			</div>
		</section>
	);
};
