import React, { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { AppRouter } from './routers/AppRouter';
import ScrollToTop from './components/ScrollToTop';
import Aos from 'aos';
import 'aos/dist/aos.css';

function VmogApp() {
	useEffect(() => {
		Aos.init({
			delay: 300,
		});
		Aos.refresh();
	}, []);

	return (
		<Router>
			<ScrollToTop />
			<AppRouter />
		</Router>
	);
}

export default VmogApp;
