import React from 'react';
import { Link } from 'react-router-dom';

// helper
import { year } from '../helpers/Date';

// Icons
import { ReactComponent as Facebook } from '../assets/icons/social/facebook.svg';
import { ReactComponent as Instagram } from '../assets/icons/social/instagram.svg';
// import { ReactComponent as Phone } from '../assets/icons/social/phone-call.svg';
import { ReactComponent as Linkedin } from '../assets/icons/social/linkedin.svg';
import { ReactComponent as Github } from '../assets/icons/social/github.svg';
import { ReactComponent as Stackoverflow } from '../assets/icons/social/stackoverflow.svg';
import { ReactComponent as Whatsapp } from '../assets/icons/social/whatsapp.svg';
import { ReactComponent as Email } from '../assets/icons/social/email.svg';

// Styles
import './footer.scss';

// Read JSON Data
const geninfo = require('../data/geninfo.json');
const geninfodata = geninfo.data;

export const Footer = () => {
	const sendEmail = () => {
		window.location.href = 'mailto:victor.ottati@gmail.com';
	};

	return (
		<div className="footer">
			<div className="footer__open"></div>
			<div className="modal hidden">
				{/* <span>{`${geninfodata[0].email}`}</span> */}
				<a
					href="fakemail@gmail.com"
					target="_blank"
					rel="noopener noreferrer"
					id="emailhref"
				>
					<p>
						Confirm: <i>I'm not a robot</i>...☑️
					</p>
				</a>
			</div>
			<div className="svc__icon footer-icon">
				<button onClick={sendEmail}>
					<Email />
				</button>
				<a
					href={geninfodata[0].facebook}
					target="_blank"
					rel="noopener noreferrer"
				>
					<Facebook />
				</a>
				<a
					href={geninfodata[0].instagram}
					target="_blank"
					rel="noopener noreferrer"
				>
					<Instagram />
				</a>
				<a
					href={geninfodata[0].linkedin}
					target="_blank"
					rel="noopener noreferrer"
				>
					<Linkedin />
				</a>
				<a
					href={geninfodata[0].whatsapp}
					target="_blank"
					rel="noopener noreferrer"
				>
					<Whatsapp />
				</a>
				<a
					href={geninfodata[0].stackoverflow}
					target="_blank"
					rel="noopener noreferrer"
				>
					<Stackoverflow />
				</a>
				<a
					href={geninfodata[0].github}
					target="_blank"
					rel="noopener noreferrer"
				>
					<Github />
				</a>

				{/* <a
					href={geninfodata[0].phonecall}
					target='_blank'
					rel='noopener noreferrer'
				>
					<Phone />
				</a> */}
			</div>
			<div className="copywrite">
				<p className="linked">
					<span className="policy-text">All Right Reserved</span>

					<Link to="/doc/privacy" className="policy">
						PRIVACY POLICY
					</Link>

					<Link to="/doc/cookies" className="policy">
						COOKIE POLICY
					</Link>
				</p>

				<p>
					<a
						href={geninfodata[0].webpage}
						target="_blank"
						rel="noopener noreferrer"
					>
						Copyright and web design by © VMOG
					</a>
				</p>
				<p className="email" onClick={sendEmail}>
					victor.ottati@gmail.com
				</p>
				<p> Liverpool UK {year} </p>
			</div>
		</div>
	);
};
