import React, { useEffect } from 'react';

// components
import { NavBar } from '../components/NavBar';
import { Contact } from '../components/Contact';
import { Footer } from '../components/Footer';

// Styles
import './contact.scss';

export const ContactPage = () => {
	useEffect(() => {
		window.scrollTo({ top: 0, behavior: 'smooth' });
	}, []);
	return (
		<>
			<header>
				<NavBar menuName={'homeOnly'} />
			</header>
			<main>
				<div style={{ margin: '6rem 0' }}>
					<Contact />
				</div>
			</main>
			<footer id="footer">
				<Footer />
			</footer>
		</>
	);
};
