import React, { useEffect } from 'react';

// components
import { NavBar } from '../components/NavBar';
import { PostSection } from '../components/PostSection';
import { ScrollBackToTop } from '../components/ScrollBackToTop';
import { Footer } from '../components/Footer';

// Read JSON Data
const posts = require('../data/posts.json');
const dataPosts = posts.posts;

export const Projects = () => {
	useEffect(() => {
		window.scrollTo({ top: 0, behavior: 'smooth' });
	}, []);
	return (
		<>
			<header>
				<NavBar menuName={'projects'} />
			</header>
			<main>
				<div className="work" id="work">
					<PostSection dataPosts={dataPosts} />
				</div>
				<ScrollBackToTop />
			</main>
			<footer id="footer">
				<Footer />
			</footer>
		</>
	);
};
