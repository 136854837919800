import React, { useEffect } from 'react';

// components
import { NavBar } from '../components/NavBar';
import { AboutComponent } from '../components/About';
import { Timeline } from '../components/Timeline';
import { ScrollBackToTop } from '../components/ScrollBackToTop';
import { Footer } from '../components/Footer';

// Styles
import './about.scss';

export const About = () => {
	useEffect(() => {
		window.scrollTo({ top: 0, behavior: 'smooth' });
	}, []);
	return (
		<>
			<header>
				<NavBar menuName={'about'} />
			</header>
			<main>
				<div style={{ margin: '5rem 0' }}>
					<AboutComponent />
				</div>
				<div id="timeline">
					<Timeline dataName={'myfirst'} />
				</div>
				<ScrollBackToTop />
			</main>
			<footer id="footer">
				<Footer />
			</footer>
		</>
	);
};
