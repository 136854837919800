import React, { useState, useRef } from 'react';
import emailjs from '@emailjs/browser';

// Styles
import './contact.scss';

export const Contact = () => {
	const [formState, setFormState] = useState({});
	const form = useRef();

	const handleChange = (event) => {
		setFormState({ ...formState, [event.target.name]: event.target.value });
	};

	const handleSubmit = (event) => {
		event.preventDefault();

		emailjs
			.sendForm(
				process.env.REACT_APP_YOUR_SERVICE_ID,
				process.env.REACT_APP_YOUR_TEMPLATE_ID,
				form.current,
				process.env.REACT_APP_YOUR_PUBLIC_KEY
			)
			.then(
				(result) => {
					// console.log('Email sent: ', result.text);
					setFormState({});
					alert('Email sent successfully');
				},
				(error) => {
					console.log(error.text);
				}
			);
	};

	return (
		<div className="form-container">
			<form ref={form} className="form-card" onSubmit={handleSubmit}>
				<h3>GET IN TOUCH</h3>
				<div className="input-container">
					<input
						type="text"
						id="name"
						name="name"
						value={formState.name || ''}
						placeholder="Your Name (optional)"
						pattern="([a-zA-Z0-9]\s?)+"
						onChange={handleChange}
					/>
					<span className="validator"></span>
				</div>
				<div className="input-container">
					<input
						type="email"
						id="email"
						name="email"
						value={formState.email || ''}
						placeholder="Your Email"
						pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}"
						required
						onChange={handleChange}
					/>
					<span className="validator"></span>
				</div>
				<div className="input-container">
					<input
						type="text"
						id="phone"
						name="phone"
						value={formState.phone || ''}
						placeholder="Phone No."
						pattern="[0-9]{10,}"
						required
						onChange={handleChange}
					/>
					<span className="validator"></span>
				</div>
				<textarea
					id="message"
					name="message"
					value={formState.message || ''}
					rows="4"
					placeholder="Write your message..."
					onChange={handleChange}
				></textarea>
				<button type="submit">Send email</button>
			</form>
		</div>
	);
};
