import React from 'react';

// ICONS
import { ReactComponent as Mongo } from '../assets/images/mongodb.svg';
import { ReactComponent as Express } from '../assets/images/express.svg';
import { ReactComponent as ReactIcon } from '../assets/images/react.svg';
import { ReactComponent as Node } from '../assets/images/nodejs.svg';
import { ReactComponent as HTML5 } from '../assets/images/html5.svg';
import { ReactComponent as CSS3 } from '../assets/images/css3.svg';
import { ReactComponent as SASS } from '../assets/images/logo-sass.svg';
import { ReactComponent as JS } from '../assets/images/javascript.svg';
import { ReactComponent as MD } from '../assets/images/markdown.svg';
import { ReactComponent as Git } from '../assets/images/git.svg';
import { ReactComponent as Github } from '../assets/images/github.svg';
import { ReactComponent as Excel } from '../assets/images/excel.svg';
import { ReactComponent as Word } from '../assets/images/word.svg';
import { ReactComponent as Jest } from '../assets/images/jest.svg';
import { ReactComponent as NPM } from '../assets/images/npm.svg';
import { ReactComponent as Postman } from '../assets/images/postman.svg';
import { ReactComponent as Vite } from '../assets/images/vitejs.svg';
import { ReactComponent as Corel } from '../assets/images/corel-draw.svg';
import { ReactComponent as Figma } from '../assets/images/figma.svg';
import { ReactComponent as Cloudinary } from '../assets/images/cloudinary.svg';
import { ReactComponent as Python } from '../assets/images/python.svg';
import { ReactComponent as Netlify } from '../assets/images/netlify.svg';
import { ReactComponent as Firebase } from '../assets/images/firebase.svg';
import { ReactComponent as Stackoverflow } from '../assets/images/stackoverflow.svg';
import { ReactComponent as Lightroom } from '../assets/images/lightroom.svg';
import { ReactComponent as AWS } from '../assets/images/aws.svg';
import { ReactComponent as Heroku } from '../assets/images/heroku.svg';
import { ReactComponent as Print } from '../assets/images/3d-printing.svg';
import { ReactComponent as Canva } from '../assets/images/canva.svg';
import { ReactComponent as LightHouse } from '../assets/images/lh_logo.svg';
import { ReactComponent as Chatgpt } from '../assets/images/chatgpt.svg';

// Styles
import './skills.scss';

export const Skills = () => {
	return (
		<section className="skills-container">
			<h2>SKILLS:</h2>
			<h3>Frontend: </h3>
			<div className="frontend">
				<HTML5 />
				<CSS3 />
				<JS />
				<SASS />
				<ReactIcon />
				<Vite />
				<LightHouse />
			</div>
			<h3>Backend: </h3>
			<div className="backend">
				<Express />
				<Node />
				<Postman />
				<Python />
			</div>
			<h3>Database and Host: </h3>
			<div className="database">
				<Mongo />
				<Cloudinary />
				<Netlify />
				<Firebase />
				<AWS />
				<Heroku />
			</div>
			<h3>Others: </h3>
			<div className="others">
				<Chatgpt />
				<MD />
				<Git />
				<Github />
				<Jest />
				<NPM />
				<Stackoverflow />
				<Figma />
				<Canva />
				<Corel />
				<Lightroom />
				<Excel />
				<Word />
				<Print />
			</div>
		</section>
	);
};
