import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

// Styles
import './navbar.scss';

// Icons
// import logoMasks from '../icons/logoMasks.svg';
import { ReactComponent as Close } from '../assets/icons/nav/close.svg';
import { ReactComponent as Menu } from '../assets/icons/nav/menu.svg';

// Read JSON Data
const menu = require('../data/navbar.json');
const dataMenu = menu.menu;

export const NavBar = ({ menuName }) => {
	const [navbar, setNavbar] = useState(false);
	const [showMenu, setShowMenu] = useState(false);

	// Filter JSON Data
	const dataFilt = dataMenu.filter((list) => list.type === menuName);
	const data = dataFilt[0].data;

	// console.log(dataFilt);

	// NAVBAR Background Change function
	useEffect(() => {
		const changeNavbarBackground = () => {
			// console.log(Math.round(window.scrollY));
			if (Math.round(window.scrollY) >= 1) {
				setNavbar(true);
			} else {
				setNavbar(false);
			}
		};

		window.addEventListener('scroll', changeNavbarBackground);

		// Unmount function
		return () => {
			window.removeEventListener('scroll', changeNavbarBackground);
		};
	}, []);

	// MENU button Toggle
	const handleClick = (e) => {
		e.preventDefault();
		setShowMenu(!showMenu);
	};

	return (
		<nav className={navbar ? 'navbar navbar__active' : 'navbar'}>
			{/* <Link to={dataFilt[0].logo.link} style={{ textDecoration: 'none' }}>
				<div className='navbar__logo'>
					<span>{dataFilt[0].logo.logo}</span>
					<span className='logo-text'>{dataFilt[0].logo.text}</span>
				</div>
			</Link> */}
			<Link to="/">
				<img src="logo192.png" alt="Logo" className="logo" />
			</Link>
			<div className="navbar__toggle" onClick={handleClick}>
				{!showMenu && (
					<Link to="/recommended" style={{ textDecoration: 'none' }}>
						<span className="especial"></span>
					</Link>
				)}
				{showMenu ? <Close /> : <Menu />}
			</div>

			<ul className={showMenu ? 'navbar__menu navbar__show' : 'navbar__menu'}>
				{data.map((item, index) => (
					<li key={`${item.id} ${index}`} className="navbar__menu-links">
						{item.link ? (
							<Link className="navbar__menu-link" to={item.link}>
								{item.name}
							</Link>
						) : (
							<a
								href={item.href}
								className="navbar__menu-link"
								target={item.target}
								rel="noreferrer"
								onClick={showMenu ? () => setShowMenu(!showMenu) : null}
							>
								{item.name}
							</a>
						)}
					</li>
				))}
			</ul>
		</nav>
	);
};
